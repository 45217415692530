import cls from 'classnames';
import {ErrorMessage} from 'formik';
import React, {useContext} from 'react';
import {FieldContext} from 'Support/Contexts/FieldContext';
import FormikContext from 'Support/Contexts/FormikContext';
import ModalContext from 'Support/Contexts/ModalContext';

const sharedWrapperClasses = 'block sm:grid sm:grid-cols-3 sm:gap-4 sm:border-gray-200';
const standardWrapperClasses = cls(sharedWrapperClasses, 'sm:px-6 sm:py-5');
const modalWrapperClasses = cls(sharedWrapperClasses, 'sm:pt-5 sm:items-start');
const borderedModalWrapperClasses = 'mt-6 sm:mt-5 sm:border-t';
const borderedStandardWrapperClasses = 'mt-8 sm:mt-0 sm:border-t';

const sharedLabelClasses = 'text-sm leading-5 font-medium';
const standardLabelClasses = cls(sharedLabelClasses, 'text-gray-500');
const modalLabelClasses = cls(sharedLabelClasses, 'block text-gray-700 sm:mt-px sm:pt-1');

const FieldWrapper = ({className, label, name, children, noBorder = false, as = 'label', ...props}) => {
  const {validationSchema} = useContext(FormikContext);
  const WrapperElement = as;
  const {isModal} = useContext(ModalContext);

  const wrapperClasses = cls({
    [standardWrapperClasses]: !isModal,
    [modalWrapperClasses]: isModal,
    [borderedModalWrapperClasses]: isModal && !noBorder,
    [borderedStandardWrapperClasses]: !isModal && !noBorder,
    className,
  });

  const field = validationSchema.describe().fields[name];
  const isRequired = field?.type !== 'boolean' && field?.tests.some(test => test.name === 'required');

  return (
    <WrapperElement className={wrapperClasses} {...props}>
      <div className={isModal ? modalLabelClasses : standardLabelClasses}>
        {label}
        {isRequired && <span className="text-red-500">*</span>}
      </div>
      <div className="mt-1 sm:mt-0 sm:col-span-2">
        <FieldContext.Provider value={{name}}>
          <div className="relative">
            {children}
          </div>
        </FieldContext.Provider>
        <ErrorMessage name={name} component="div" className="bg-red-500 text-white p-2 text-xs ml-auto -mt-1 pt-3 shadow-sm rounded-b-md"/>
      </div>
    </WrapperElement>
  );
};

export default FieldWrapper;